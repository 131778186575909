// Copyright @yucwang 2022

import { graphql, PageProps } from "gatsby"
import * as React from "react"
import MainLayout from "../components/layout"

import Gallery from '@browniebroke/gatsby-image-gallery'
import * as PhotoGallaryPageStyle from "./photoGallary.module.css"

const GalleryPage = ({ data }) => {
  const usImages = data.usImage.edges.map(({ node }) => node.childImageSharp)
  const twImages = data.twImage.edges.map(({ node }) => node.childImageSharp)
  // `images` is an array of objects with `thumb` and `full`
  return (
      <MainLayout>
        <h2> Gallery </h2>
        <div className={ PhotoGallaryPageStyle.contentContainer} >
          <h3>Taiwan</h3>
          <Gallery images = { twImages }/>
          <h3>United States</h3>
          <Gallery images = { usImages }/>
        </div>
      </MainLayout>
  )
}

export const pageQuery = graphql`
  query ImagesForGallery {
    usImage: allFile(filter: {sourceInstanceName: { eq: "gallery_images" },
                              relativeDirectory:  { eq: "US"}}) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 512
              height: 512
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    twImage: allFile(filter: {sourceInstanceName: { eq: "gallery_images" },
                              relativeDirectory:  { eq: "Taiwan"}}) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 512
              height: 512
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default GalleryPage
